@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f7f7f7;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Layout Styles */
.home {
  max-width: 1200px;
  margin: 40px auto;
  padding: 20px;
}

.agents {
  padding: 20px 0;
}

.agent-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
  padding: 20px;
}

/* Agent Card Styles */
.agent-card {
  background: white;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.agent-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 15px rgba(0,0,0,0.2);
}

.agent-card h2 {
  color: #2c3e50;
  margin: 0 0 10px 0;
  font-size: 1.5rem;
}

.agent-card p {
  color: #666;
  margin: 0;
  font-size: 1.1rem;
}

/* Error Message Styles */
.home p:first-child {
  color: #e74c3c;
  text-align: center;
  padding: 20px;
  background: #fde8e8;
  border-radius: 8px;
  margin-bottom: 20px;
}

@keyframes gradient {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

.animate-gradient {
  animation: gradient 6s ease infinite;
  background-size: 200% 200%;
}

/* Tech Animation Styles */
.binary-rain .binary-column {
  position: absolute;
  top: -20px;
  color: #f59e0b;
  font-family: monospace;
  font-size: 20px;
  animation: rain 10s linear infinite;
  opacity: 0.3;
}

@keyframes rain {
  0% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(100vh);
  }
}

.circuit-line {
  position: absolute;
  height: 2px;
  width: 100%;
  opacity: 0;
}

.tech-grid .grid-pattern {
  background-image: 
    linear-gradient(to right, #f59e0b 1px, transparent 1px),
    linear-gradient(to bottom, #f59e0b 1px, transparent 1px);
  background-size: 50px 50px;
  width: 100%;
  height: 100%;
  animation: gridMove 20s linear infinite;
}

@keyframes gridMove {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(50px, 50px);
  }
}

.particles {
  filter: blur(1px);
}

/* Add glow effect to particles on hover */
.particle:hover {
  filter: blur(2px);
  box-shadow: 0 0 10px #f59e0b, 0 0 20px #f59e0b, 0 0 30px #f59e0b;
}

/* Circuit pattern animation */
.circuit-pattern {
  mask-image: linear-gradient(to right, transparent, black, transparent);
}

/* Add pulse animation to tech elements */
@keyframes pulse-glow {
  0%, 100% {
    opacity: 0.1;
    filter: blur(1px);
  }
  50% {
    opacity: 0.3;
    filter: blur(2px);
  }
}

.tech-element {
  animation: pulse-glow 4s ease-in-out infinite;
}
